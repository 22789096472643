<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 11:46:07
 * @LastEditors: liutq
 * @LastEditTime: 2025-03-28 15:53:27
-->
<template>
    <div class="report-wrapper fix-background">
        <div :class="['section', $i18n.locale]">
            <div class="header-menu" v-if="share">
                <span>
                    <img
                        src="@/assets/images/sidebar/report_leftber_Info@2x.png"
                        alt
                        class="menu-info"
                        @click.prevent="openSide"
                    />
                </span>
                <div :class="qkStatus ? 'product-name-qk' : 'product-name'">
                    <span v-if="deviceType === 1"> V-R Explorer </span>
                    <span v-else-if="deviceType === 3"> Visbody-M30 </span>
					<span v-else-if="deviceType === 4"> V-M60 </span>
                    <span v-else> V-S30 </span>
                </div>
                <!-- 右边按钮 -->
                <div class="right-menu-right">
                    <!-- <div v-if="deviceType === 3" @click="goToScanQrcode()">
                            <nav>
                                <img src="@/assets/images/sidebar/report_scan@2x.png" alt class="right-menu-icon" />
                            </nav>
                        </div> -->

                    <div @click="goToShare()">
                        <nav>
                            <img src="@/assets/images/sidebar/history@2x.png" alt class="right-menu-icon" />
                        </nav>
                    </div>
                    <div @click="goToSaveScanQrcode()">
                        <nav>
                            <img src="@/assets/images/sidebar/image-add-line@2x.png" alt class="right-menu-icon" />
                        </nav>
                    </div>
                    <div @click="goShare()">
                        <nav>
                            <img src="@/assets/images/sidebar/external-link-line@3x.png" alt class="right-menu-icon" />
                        </nav>
                    </div>
                </div>
            </div>

            <!-- 标签页 -->
            <div :class="qkStatus ? '' : 'qk-box'">
                <van-tabs v-model="activeName" @click="onClick" sticky :ellipsis="false">
                    <div class="content-menu">
                        <span class="time">{{ scanTime }}</span>
                        <!-- 右边按钮 -->
                        <div v-if="loading" class="right-menu">
                            <nav class="last-trend" @click="goTrend" v-if="trendStatus">
                                <img src="@/assets/images/report/trend.png" alt class="left-icon" />
                            </nav>
                            <nav v-if="share" class="send-report" @click="sendReport">
                                <img src="@/assets/images/report/mail-line.png" alt class="right-icon" />
                            </nav>
                        </div>
                    </div>
                    <!-- 用户信息 -->
                    <div>
                        <info :massInfo="massInfo"></info>
                    </div>
                    <!-- 非m30 -->
                    <template v-if="deviceType !== 3">
                        <van-tab
                            :title="$t('mass.titles')"
                            name="body"
                            v-if="massInfo.biaMeasure == 1 && massInfo.biaStatus == 1"
                        >
                            <!-- 评估总览 只有M30不展示体成分评估模块 -->
                            <div v-if="deviceType !== 3">
                                <assessment  :isScore="isScore" :scoreType="1" :massInfo="massInfo"></assessment>
                            </div>
                            <!-- 报告对比 -->
                            <div v-if="dataArrayMass.length > 1">
                                <body-item :isCreateTime="createTime"></body-item>
                            </div>
                            <div>
                                <!-- 身体成分数据 -->
                                <body-data :massInfo="massInfo" :styleContent="dataArrayMass.length"></body-data>
                            </div>
                            <div v-if="deviceType !== 3">
                                <segment-data :newMassInfo="massInfo"></segment-data>
                            </div>
                        </van-tab>
                        <van-tab
                            :title="$t('shape.titles')"
                            name="posture"
                            v-if="massInfo.staticEval == 1 && massInfo.evalStatus == 1"
                        >
                            <div>
                                <shape-item  :assess="this.isNewMathTt ? assessNew : assess" :qkStatus="qkStatus"></shape-item>
                            </div>
                            <!-- 评估总览 -->
                            <div>
                                <assessment :isScore="isScore"  :scoreType="2" :massInfo="massInfo"></assessment>
                            </div>
                            <!-- 数据对比 -->
                            <div>
                                <shape-data  :massInfo="massInfo" @assessProblem="assessProblem"></shape-data>
                            </div>
                        </van-tab>
                        <van-tab
                            :title="$t('girth.title')"
                            name="bodyround"
                            v-if="massInfo.girthMeasure == 1 && massInfo.bdaStatus == 1"
                        >
                            <!-- 体围测量 -->
                            <div>
                                <girth-item v-if="dispalyModel" :isCreateTime="createTime"></girth-item>
                            </div>
                        </van-tab>
                        <van-tab
                            :title="$t('shoulder.title')"
                            name="shoulder"
                            v-if="
                                (massInfo.shoulder == 1 || massInfo.shoulderEval == 1) &&
                                (massInfo.bsShoulderScanResult == 1 || massInfo.bsShoulderStatus == 1)
                            "
                        >
                            <!-- 肩部结论 -->
                            <div>
                                <shoulder-data :massInfo="massInfo"></shoulder-data>
                            </div>
                            <div>
                                <shoulder-item :massInfo="massInfo"></shoulder-item>
                            </div>
                        </van-tab>
						<van-tab
							:title="$t('bodyPrediction.body-title')"
							name="prediction"
							v-if="massInfo.biaMeasure == 1 && massInfo.bdaStatus == 1 && massInfo.biaStatus == 1 && deviceType == 4"
							
						>
							<!-- 体型预测 -->
							<div v-if="predictionModel">
								<prediction-item></prediction-item>
								<prediction-data class="prediction-data-box"></prediction-data>
							</div>
						</van-tab>
                    </template>
                    <!-- m30 -->
                    <template v-if="deviceType === 3">
                        <van-tab
                            :title="$t('shape.titles')"
                            name="posture"
                            v-if="massInfo.staticEval == 1 && massInfo.evalStatus == 1"
                        >
                            <div>
                                <shape-item :assess="isNewMathTt ? assessNew : assess" :qkStatus="qkStatus"></shape-item>
                            </div>
                            <!-- 评估总览 -->
                            <div>
                                <assessment :isScore="isScore" :scoreType="2" :massInfo="massInfo"></assessment>
                            </div>
                            <!-- 数据对比 -->
                            <div>
                                <shape-data :massInfo="massInfo" @assessProblem="assessProblem"></shape-data>
                            </div>
                        </van-tab>
                        <van-tab
                            :title="$t('girth.title')"
                            name="bodyround"
                            v-if="massInfo.girthMeasure == 1 && massInfo.bdaStatus == 1"
                        >
                            <!-- 体围测量 -->
                            <div>
                                <girth-item v-if="dispalyModel" :isCreateTime="createTime"></girth-item>
                            </div>
                        </van-tab>
                        <van-tab
                            :title="$t('shoulder.title')"
                            name="shoulder"
                            v-if="
                                (massInfo.shoulder == 1 || massInfo.shoulderEval == 1) &&
                                (massInfo.bsShoulderScanResult == 1 || massInfo.bsShoulderStatus == 1)
                            "
                        >
                            <!-- 肩部结论 -->
                            <div>
                                <shoulder-data :massInfo="massInfo"></shoulder-data>
                            </div>
                            <div>
                                <shoulder-item :massInfo="massInfo"></shoulder-item>
                            </div>
                        </van-tab>
                        <van-tab
                            :title="$t('mass.titles')"
                            name="body"
                            v-if="massInfo.biaMeasure == 1 && massInfo.biaStatus == 1"
                        >
                            <!-- 评估总览 只有M30不展示体成分评估模块 -->
                            <div v-if="deviceType !== 3">
                                <assessment :isScore="isScore" :scoreType="1" :massInfo="massInfo"></assessment>
                            </div>
                            <!-- 报告对比 -->
                            <div v-if="dataArrayMass.length > 1">
                                <body-item :isCreateTime="createTime"></body-item>
                            </div>
                            <div>
                                <!-- 身体成分数据 -->
                                <body-data :massInfo="massInfo" :styleContent="dataArrayMass.length"></body-data>
                            </div>
                            <div v-if="deviceType !== 3">
                                <segment-data :newMassInfo="massInfo"></segment-data>
                            </div>
                        </van-tab>
                    </template>
                </van-tabs>
            </div>

            <!--侧边栏-->
            <van-popup
                v-model="popupVisible"
                :position="$i18n.locale === 'ar-AR' ? 'right' : 'left'"
                :style="{ height: '100%', width: '70%' }"
            >
                <SideBar />
            </van-popup>
        </div>
        <div class="fixedBox"></div>
        <div class="spring-frame" v-if="springStatus" style="background: #000000; opacity: 0.54"></div>
        <div class="spring-frame" v-if="springStatus">
            <div class="spring-frame-box">
                <h6>{{ $t('reportTips.alaryTips') }}</h6>
                <button @click="changeStatus()">{{ $t('reportTips.btnMsg') }}</button>
            </div>
        </div>
        <van-popup v-model="show" :class="[$i18n.locale]">
            <div class="share-box">
                <p>{{ $t('share.title') }}</p>
                <div class="share-box-dev">
                    <span>
                        {{ textToCopy }}
                    </span>
                </div>
                <div class="share-box-btn" @click="copyText()">
                    {{ $t('share.btnMsg') }}
                </div>
            </div>
        </van-popup>
        <!-- TODO: 临时去掉 -->
        <!-- <img v-if="aiStatue.abnormalKeywordInterpret !== null" class="ai-entrance" src="@/assets/images/report/ai3.png" alt="" @click="goAi()">
        
        <ai-loading v-if="aiLoading" :aiData="aiData" @goBack="aiLoading = false" @goAi="goAiUrl"></ai-loading> -->
    </div>
</template>

<script>
import { formatMinutes } from '@/assets/js/util.js'
import SideBar from '@/components/sidebar/index'
import { reportHost } from '@/assets/js/config.js'
import Info from '@/components/user/Info'
import Assessment from '@/components/common/Assessment'
import SegmentData from '@/components/main/SegmentData'
import ShapeItem from '@/components/body/ShapeItemNew'
import BodyData from '@/components/body/BodyData'
import GirthItem from '@/components/body/GirthItem'
import BodyItem from '@/components/body/BodyItem'
import ShapeData from '@/components/body/ShapeData'
import ShoulderData from '@/components/body/ShoulderData'
import ShoulderItem from '@/components/body/ShoulderItem'
// import AiLoading from '@/components/ai/aiLoading.vue'
import { mapState } from 'vuex'
import { findScanTypeInfo, getUserScr } from '@/assets/js/apolloGql.js'
import {_parseTimezoneToMinutes } from '@/assets/js/util.js'

import predictionItem from '@/components/body/predictionitem'
import predictionData from '@/components/body/predictionData'
export default {
    // name: 'Report',
    components: {
        SideBar,
        Info,
        Assessment,
        SegmentData,
        ShapeItem,
        BodyData,
        GirthItem,
        BodyItem,
        ShapeData,
        ShoulderData,
        ShoulderItem,
        // AiLoading,
		predictionItem,
		predictionData
    },
    data() {
        return {
            share: JSON.parse(localStorage.getItem('share')) === false ? false : true,
            textToCopy: '',
            show: false,
            springStatus: false,
            popupVisible: false,
            loading: true,
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            deviceType: JSON.parse(window.localStorage.getItem('deviceType')),
            reportImgs: [
                // img1,
                // img2,
                // img3,
            ],
            // 成功报告数量
            successReportCount: 0,
            // 报告图片加载数量
            reportImageLoadedCount: 0,
            // 标题名称
            activeName: 'body',
            bodyScore: {},
            massScore: {},
            evalScore: {},
            bodyState: {},
            massLevel: {},
            evalLevel: {},
            scoreType: 0,
            scanTime: '',
            isAssess: false,
            createTime: '',
            isScore: 0,
            // 体态评估
            assess: [
                {
                    title: this.$t('shape.models.front'),
                    list: [
                        {
                            name: this.$t('shape.explain[1].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['headSlant'],
                            errorPoints: ['keyPoint0', 'keyPoint4'],
                        },
                        {
                            name: this.$t('shape.explain[8].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['rightLegXOUp', 'rightLegXODown', 'leftLegXOUp', 'leftLegXODown'],
                            errorPoints: [
                                'keyPoint8',
                                'keyPoint9',
                                'keyPoint10',
                                'keyPoint11',
                                'keyPoint12',
                                'keyPoint13',
                            ],
                        },
                    ],
                },
                {
                    title: this.$t('shape.models.left'),
                    list: [
                        {
                            name: this.$t('shape.explain[0].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['headForward'],
                            errorPoints: ['keyPoint0', 'keyPoint4'],
                        },
                        {
                            name: this.$t('shape.explain[2].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['roundShoulderLeft'],
                            errorPoints: ['keyPoint16', 'keyPoint1'],
                        },
                        {
                            name: this.$t('shape.explain[5].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['pelvisForwardUp', 'pelvisForwardDown'],
                            errorPoints: ['keyPoint4', 'keyPoint15', 'keyPoint6'],
                        },
                        {
                            name: this.$t('shape.explain[6].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['leftKneeCheckUp', 'leftKneeCheckDown'],
                            errorPoints: ['keyPoint11', 'keyPoint12', 'keyPoint13'],
                        },
                    ],
                },
                {
                    title: this.$t('shape.models.top'),
                    list: [
                        {
                            name: this.$t('shape.explain[4].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['highLowShoulder'],
                            errorPoints: ['keyPoint2', 'keyPoint5'],
                        },
                    ],
                },
                {
                    title: this.$t('shape.models.right'),
                    list: [
                        {
                            name: this.$t('shape.explain[3].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['roundShoulderRight'],
                            errorPoints: ['keyPoint18', 'keyPoint3'],
                        },
                        {
                            name: this.$t('shape.explain[7].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['rightKneeCheckUp', 'rightKneeCheckDown'],
                            errorPoints: ['keyPoint8', 'keyPoint9', 'keyPoint10'],
                        },
                    ],
                },
            ],
             // 体态评估
            assessNew: [
                {
                    title: this.$t('shape.models.front'),
                    list: [
                        {
                            name: this.$t('shape.explain[1].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['headSlant'],
                            errorPoints: ['left_ear_point', 'right_ear_point', 'right_body_gravity_line_point', 'left_body_gravity_line_point'],
                        },
                        {
                            name: this.$t('shape.explain[8].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['rightLegXOUp', 'rightLegXODown', 'leftLegXOUp', 'leftLegXODown', 'leftLegXO', 'rightLegXO'],
                            errorPoints: ['left_knee_joint_point', 'left_hip_joint_point', 'left_ankle_point', 'right_knee_joint_point', 'right_hip_joint_point', 'right_ankle_point']
                        },
                    ],
                },
                {
                    title: this.$t('shape.models.left'),
                    list: [
                        {
                            name: this.$t('shape.explain[2].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['roundShoulderLeft'],
                            errorPoints: ['left_acromion_point', 'left_body_gravity_line_point', 'left_acromion_point1', 'left_body_gravity_line_point1'],
                        },
                        {
                            name: this.$t('shape.explain[6].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['leftKneeCheckUp', 'leftKneeCheckDown'],
                            errorPoints: ['left_hip_joint_point', 'left_knee_joint_below_point', 'left_ankle_point'],
                        },
                    ],
                },
                {
                    title: this.$t('shape.models.top'),
                    list: [
                        {
                            name: this.$t('shape.explain[4].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['highLowShoulder', 'highLowShoulderLeft', 'highLowShoulderRight'],
                            errorPoints: ['left_shoulder_neck_root_point', 'left_acromion_point', 'right_shoulder_neck_root_point', 'right_acromion_point'],
                        },
                    ],
                },
                {
                    title: this.$t('shape.models.right'),
                    list: [
                        {
                            name: this.$t('shape.explain[3].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['roundShoulderRight'],
                            errorPoints: ['right_acromion_point', 'right_body_gravity_line_point', 'right_acromion_point1', 'right_body_gravity_line_point1'],
                        },
                        {
                            name: this.$t('shape.explain[7].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['rightKneeCheckUp', 'rightKneeCheckDown'],
                            errorPoints: ['right_hip_joint_point', 'right_knee_joint_below_point', 'right_ankle_point'],
                        },
                    ],
                },
            ],
            // 历史信息
            massInfo: { biaMeasure: 0, staticEval: 0, shoulder: 0, girthMeasure: 0 },
            trendStatus: true,
            dispalyModel: true,
            lastScanTime: '', // 测量时间
            qkStatus: false,
            // 是否为新算法
            isNewMathTt: 0,
            isNewStatus: false,
            aiUrl: '',
            aiStatue: {
                abnormalKeywordInterpret: null,
                abnormalConclusionInterpret: null,
                abnormalOutlineInterpret: null
            },
            aiLoading: false,
            aiData: {},
			predictionModel: false
        }
    },
    computed: {
        ...mapState(['historyData', 'dataArray', 'dataArrayMass', 'sidebarData']),

        // 格式化报告时间
        reportTime() {
            return formatMinutes(new Date(this.modelInfo.createTime * 1000))
        },
    },
    created() {
        window.addEventListener('pageshow', function(event) {
            // 检测页面是否从缓存中加载
            if (event.persisted) {
                window.location.reload();
            }
        });
        // 兼容夸克浏览器
        var userAgent = navigator.userAgent
        if (userAgent.indexOf('Quark') > -1) {
            this.qkStatus = true
        }
        // m30时，更新tab默认标题名称
        this.updateTitle()

        let modelInfo = window.localStorage.getItem('modelInfo')
        if (modelInfo === null) {
            this.$router.push({ path: '/' })
            return
        }

        console.log(`当前设备类型${this.deviceType} `)
        if (this.$route.params.type === 1) {
            this.popupVisible = true
        }
        // 更新测量信息
        let masseursDate = '', UTC = '';
        if (this.historyData.createDate && this.$route.query.type == 1) {
            masseursDate = this.historyData.createTime
			UTC = this.historyData.utc
            this.massInfo = this.historyData
            this.$store.commit('setHistoryScanId', this.massInfo.scanId)
            if (
                (this.massInfo.shoulder && !this.massInfo.staticEval) ||
                (this.massInfo.staticEval && !this.massInfo.shoulder && !this.massInfo.biaMeasure)
            ) {
                this.trendStatus = false
            }
        } else {
            if (this.$route.params.type === 1) {
                if (this.sidebarData) {
                    masseursDate = this.sidebarData.createTime
					UTC = this.historyData.utc
                    this.massInfo = this.sidebarData
                }
            } else {
                masseursDate = this.modelInfo.createTime
				UTC = this.modelInfo.utc
                this.massInfo = this.modelInfo
            }

            if (
                (this.massInfo.shoulderEval && !this.massInfo.staticEval && !this.massInfo.biaMeasure) ||
                (this.massInfo.staticEval && !this.massInfo.shoulderEval && !this.massInfo.biaMeasure)
            ) {
                this.trendStatus = false
            }
        }
        console.log(`当前报告数据${JSON.stringify(this.massInfo)}     lang: ${this.$i18n.locale}`, UTC)
        const formattedTimezone = UTC.replace(/^(UTC|GMT)/, '').trim();
		const baseMoment = this.$moment(masseursDate * 1000).utcOffset(formattedTimezone);

		if (this.$i18n.locale === 'ru-RU' || this.$i18n.locale === 'ar-AR') {
			this.scanTime = baseMoment.format('DD/MM/YYYY HH:mm');
			this.createTime = baseMoment.format('DD/MM/YYYY');
		} else {
			this.scanTime = baseMoment.format('YYYY/MM/DD HH:mm');
			this.createTime = baseMoment.format('YYYY/MM/DD');
		}
        // 获取成功的报告
        const successReport = [
            this.modelInfo.biaStatus,
            this.modelInfo.evalStatus,
            this.modelInfo.bsShoulderScanResult,
        ].filter((status) => status === 1)
        this.successReportCount = successReport.length
        const unit = window.localStorage.getItem('unit')
        successReport.forEach((val, idx) => {
            this.reportImgs.push(
                `${reportHost}/report/vr-exp/img/${this.$i18n.locale}/${unit}/${this.modelInfo.scanId}.png?page=${
                    idx + 1
                }`
            )
        })
    },
    mounted() {
        // if((this.massInfo.shoulder || this.massInfo.shoulderEval) && (!this.massInfo.biaMeasure || !this.massInfo.staticEval || this.massInfo.staticEval)){
        //     this.trendStatus = false
        // }
		this.queryScanId()
    },
    methods: {
        updateTitle() {
            let shareScanId = window.localStorage.getItem('shareScanId')
            //  分享链接进入取分享报告ScanId
            let shareFlag = !this.share && shareScanId

            // m30时，更新tab默认标题名称
            if (this.deviceType === 3 && !shareFlag) {
                this.activeName = 'posture'
            }

            if (this.activeName === 'posture' || this.activeName === 'shoulder' || this.activeName === 'prediction') {
                this.trendStatus = false
            } else {
                this.trendStatus = true
            }
        },
        changeStatus() {
            this.springStatus = false
            this.$router.push({
                path: '/',
                query: {
                    type: 1,
                },
            })
        },
        async queryScanId() {
            if (this.historyScanId === undefined && this.massInfo.scanId === undefined) {
                return
            }
            let shareScanId = window.localStorage.getItem('shareScanId')
            //  分享链接进入取分享报告ScanId
            let shareFlag = !this.share && shareScanId

            const scanResult = await this.$apollo.query({
                query: findScanTypeInfo,
                fetchPolicy: 'network-only',
                variables: {
                    scanId: shareFlag ? shareScanId : this.historyScanId ? this.historyScanId : this.massInfo.scanId,
                    unit: window.localStorage.getItem('unit'),
                    lang: window.localStorage.getItem('lang')
                },
            })

            const scanResultData = scanResult.data.findScanTypeInfo
            this.isNewMathTt = scanResultData.data.isNewMathTt
            this.$store.commit('setIsNewMathTt', scanResultData.data.isNewMathTt)
            this.aiUrl = scanResultData.data.aiUrl
            this.aiStatue.abnormalKeywordInterpret = scanResultData.data.abnormalKeywordInterpret
            this.aiStatue.abnormalConclusionInterpret = scanResultData.data.abnormalConclusionInterpret
            this.aiStatue.abnormalOutlineInterpret = scanResultData.data.abnormalOutlineInterpret
            if (shareFlag) {
                const newDate = scanResultData.data
                const massResult = {
                    ...newDate,
                    bdaStatus: newDate.bmBdaScanInfo ? newDate.bmBdaScanInfo.result : 4,
                    biaStatus: newDate.bmBiaScanInfo ? newDate.bmBiaScanInfo.result : 4,
                    bsShoulderScanResult: newDate.bsShoulderScanInfo ? newDate.bsShoulderScanInfo.result : 4,
                    evalStatus: newDate.bsScanInfo ? newDate.bsScanInfo.result : 4,
                    shoulder: newDate.shoulderJointEval,
                }

                this.massInfo = massResult
                setTimeout(() => {
                    if (massResult.biaMeasure == 1 && massResult.biaStatus == 1) {
                        this.activeName = 'body'
                    } else if (massResult.staticEval == 1 && massResult.evalStatus == 1) {
                        this.activeName = 'posture'
                    } else if (massResult.girthMeasure == 1 && massResult.bdaStatus == 1) {
                        this.activeName = 'bodyround'
                    }
                    this.updateTitle()
                }, 0)
				const formattedTimezone = _parseTimezoneToMinutes(massResult.utc.replace(/^(UTC|GMT)/, '').trim())
                this.createTime = this.$moment(massResult.createTime * 1000).utcOffset(formattedTimezone).format('YYYY/MM/DD')
            }
            const getDeviceInfo = await this.$apollo.query({
                query: getUserScr,
                fetchPolicy: 'network-only',
                variables: {
                    deviceId: scanResultData.data.deviceId,
                },
            })
            this.isScore = getDeviceInfo.data.getUserScr.data.isScore
            try {
				const timezone =_parseTimezoneToMinutes(scanResultData.data.utc.replace(/^(UTC|GMT)/, '').trim())
                this.scanTime = this.$moment(scanResultData.data.createTime * 1000).utcOffset(timezone).format('YYYY/MM/DD HH:mm')
                if (this.$i18n.locale === 'ru-RU'  || this.$i18n.locale === 'ar-AR') {
                    this.scanTime = this.$moment(scanResultData.data.createTime * 1000).utcOffset(timezone).format('DD/MM/YYYY HH:mm')
                }
            } catch (e) {
                console.log(`请求失败${e}`)
            }

            // this.lastScanTime =
            if (scanResultData.code === 404) {
                this.springStatus = true
                return
            }
        },
        async onClick(name) {
            const scanResult = await this.$apollo.query({
                query: findScanTypeInfo,
                fetchPolicy: 'network-only',
                variables: {
                    scanId: this.historyScanId ? this.historyScanId : this.massInfo.scanId,
                    unit: window.localStorage.getItem('unit'),
                    lang: window.localStorage.getItem('lang')
                },
            })

            const scanResultData = scanResult.data.findScanTypeInfo
            this.aiUrl = scanResultData.data.aiUrl
            if (scanResultData.code === 404) {
                this.springStatus = true
                return
            }
            this.dispalyModel = false
			this.predictionModel = false
            this.name = name
            if (name === 'posture' || name === 'shoulder' || name === 'prediction') {
                this.trendStatus = false
            } else {
                this.trendStatus = true
            }
            if (name == 'bodyround') {
                this.$nextTick(() => {
                    this.dispalyModel = true
                })
            }
			if (name == 'prediction') {
                this.$nextTick(() => {
                    this.predictionModel = true
                })
            }
        },
        // 跳转历史页面
        goToShare() {
            this.$router.push({
                path: '/report/historyPage',
            })
        },
        // 跳转分享
        goShare() {
            this.show = true
            const url = window.location.href.replace('report', 'login')
            const scanId = url.includes('type') ? `&${this.massInfo.scanId}` : ''
            const pre =
                sessionStorage.getItem('token') +
                '&' +
                localStorage.getItem('unit') +
                '&' +
                localStorage.getItem('lang') +
                '&' +
                Date.now() +
                '-' +
                localStorage.getItem('deviceType') +
                scanId
            if (url.includes('?')) {
                this.textToCopy = url + '&' + pre
            } else {
                this.textToCopy = url + '?' + pre
            }
        },
        copyText() {
            // 内容
            this.$copyText(this.textToCopy).then(
                () => {
                    console.log('success')
                    this.show = false
                },
                function () {
                    console.log('error')
                }
            )
        },
        // 跳转二维码扫描页面
        goToScanQrcode() {
            this.$router.push({
                path: '/qrcode/index',
            })
        },
        // 跳转保存二维码页面
        goToSaveScanQrcode() {
            this.$router.push({
                path: '/qrcode/save',
            })
        },
        //
        // 展开侧边栏
        openSide() {
            this.$store.commit('setSidebarData', this.massInfo)
            this.popupVisible = true
        },
        sendReport() {
            this.$router.push({ path: '/report/send', query: { type: this.$route.query.type } })
        },
        // 趋势图按钮
        goTrend() {
            if (this.name === 'bodyround') {
                this.$router.push('/girth/trend')
            } else {
                this.$router.push('/body/trend')
            }
        },
        reportImageLoaded() {
            this.reportImageLoadedCount++
            if (this.reportImageLoadedCount === this.successReportCount) {
                this.loading = false
            }
        },
        handleDownload() {
            const unit = window.localStorage.getItem('unit')
            const a = document.createElement('a')
            a.download = `${this.modelInfo.scanId}.png`
            a.href = `${reportHost}/report/vr-exp/img/${this.$i18n.locale}/${unit}/${this.modelInfo.scanId}.png`
            document.body.appendChild(a)
            a.click()
            a.remove()
        },
        touchstart() {
            this.lastY = event.changedTouches[0].clientY // 点击屏幕时记录最后一次Y坐标
        },
        touchmove() {
            let y = event.changedTouches[0].clientY
            let st = this.$refs.sectionBox.scrollTop // 滚动条高度
            if (y >= this.lastY && st <= 0) {
                //若滚动到顶部且为下拉情况
                this.lastY = y
                event.preventDefault()
            }
            let clientHeight = document.querySelector('.section').clientHeight
            let scrollHeight = document.querySelector('.section').scrollHeight
            if (st + clientHeight === scrollHeight && y < this.lastY) {
                // 若滚动到底部且为上拉情况
                this.lastY = y
                event.preventDefault()
            }
            this.lastY = y
        },

        // 体态评估上半模块数据
        assessProblem(data, bsDataState) {
			console.log("isNewMathTt", "=====", this.isNewMathTt)
            if(!this.isNewMathTt){
                if (data.headForward > 0) {
                    this.assess[1].list[0].status = this.$t('shape.item.abnormal')
                }
                if (data.headSlant > 0) {
                    this.assess[0].list[0].status = this.$t('shape.exception[0].title')
                } else if (data.headSlant < 0) {
                    this.assess[0].list[0].status = this.$t('shape.exception[1].title')
                }
                if (data.roundShoulderLeft > 15) {
                    this.assess[1].list[1].status = this.$t('shape.item.abnormal')
                }
                if (data.roundShoulderRight > 15) {
                    this.assess[3].list[0].status = this.$t('shape.item.abnormal')
                }
                if (data.highLowShoudler > 0) {
                    this.assess[2].list[0].status = this.$t('shape.exception[2].title')
                } else if (data.highLowShoudler < 0) {
                    this.assess[2].list[0].status = this.$t('shape.exception[3].title')
                }
                if (data.pelvisForward > 185) {
                    this.assess[1].list[2].status = this.$t('shape.exception[4].title')
                } else if (data.pelvisForward < 175) {
                    this.assess[1].list[2].status = this.$t('shape.exception[5].title')
                }
                if (data.leftKneeCheck > 190) {
                    this.assess[1].list[3].status = this.$t('shape.exception[6].title')
                } else if (data.leftKneeCheck < 160) {
                    this.assess[1].list[3].status = this.$t('shape.exception[7].title')
                }
                if (data.rightKneeCheck > 190) {
                    this.assess[3].list[1].status = this.$t('shape.exception[8].title')
                } else if (data.rightKneeCheck < 160) {
                    this.assess[3].list[1].status = this.$t('shape.exception[9].title')
                }
                if (data.leftLegXo <= 190 && data.leftLegXo >= 170) {
                    if (data.rightLegXo >= 0 && data.rightLegXo < 170) {
                        this.assess[0].list[1].status = this.$t('shape.exception[10].title')
                    } else if (data.rightLegXo > 190 && data.rightLegXo <= 360) {
                        this.assess[0].list[1].status = this.$t('shape.exception[11].title')
                    }
                } else if (data.leftLegXo >= 0 && data.leftLegXo < 170) {
                    if (data.rightLegXo <= 190 && data.rightLegXo >= 170) {
                        this.assess[0].list[1].status = this.$t('shape.exception[10].title')
                    } else if (data.rightLegXo >= 0 && data.rightLegXo < 170) {
                        this.assess[0].list[1].status = this.$t('shape.exception[12].title')
                    } else if (data.rightLegXo > 190 && data.rightLegXo <= 360) {
                        this.assess[0].list[1].status = this.$t('shape.item.abnormal')
                    }
                } else if (data.leftLegXo > 190 && data.leftLegXo <= 360) {
                    if (data.rightLegXo <= 190 && data.rightLegXo >= 170) {
                        this.assess[0].list[1].status = this.$t('shape.exception[11].title')
                    } else if (data.rightLegXo > 190 && data.rightLegXo <= 360) {
                        this.assess[0].list[1].status = this.$t('shape.exception[13].title')
                    } else if (data.rightLegXo > 190 && data.rightLegXo <= 360) {
                        this.assess[0].list[1].status = this.$t('shape.item.abnormal')
                    }
                }
            } else if(JSON.stringify(bsDataState) !== '{}'){
				this.$store.commit('setNewMathTtData', bsDataState)
                // 进行新算法数据整理
                if(!this.isNewStatus){
                    // 只修改一次数据
                    this.isNewStatus = !this.isNewStatus
                    return
                }
                if (bsDataState.headSlantState === 2 || bsDataState.headSlantState === 4) {
                    this.assessNew[0].list[0].status = this.$t('shape.exception[0].title')
                } else if (bsDataState.headSlantState === 1 || bsDataState.headSlantState === 3) {
                    this.assessNew[0].list[0].status = this.$t('shape.exception[1].title')
                }
                // 腿型
                switch (bsDataState.legType) {
					case 0:
                        break;
                    case 1:
                        this.assessNew[0].list[1].status = this.$t('shape.item.abnormal')
                        break;
                    case 2:
                        this.assessNew[0].list[1].status = this.$t('shape.exception[10].title')
                        break;
                    case 3:
                        this.assessNew[0].list[1].status = this.$t('shape.exception[11].title')
                        break;
                    case 4:
                        this.assessNew[0].list[1].status = this.$t('shape.exception[12].title')
                        break;
                    case 5:
                        this.assessNew[0].list[1].status = this.$t('shape.exception[13].title')
                        break;
                
                    default:
						this.assessNew[0].list[1].status = this.$t('shape.item.keAbnormal')
                        break;
                }

                let leftHead = 0, rightHead = 0
                // 左侧面
				console.log(bsDataState.hsVisualization, 88888899999)
                if(bsDataState.hsVisualization){
                    this.assessNew[1].list.unshift(
                        {
                            name: this.$t('shape.explain[0].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['headForward'],
                            errorPoints: ['left_ear_point', 'right_ear_point']
                        },
                    )
                    if(bsDataState.headForwardState){
                        this.assessNew[1].list[0].status = this.$t('shape.item.abnormal')
                    }
                    leftHead++;

                // 右侧面
                } else {
                    this.assessNew[3].list.unshift(
                        {
                            name: this.$t('shape.explain[0].title'),
                            status: this.$t('shape.item.normal'),
                            errorLines: ['headForward'],
                            errorPoints: ['left_ear_point', 'right_ear_point']
                        }
                    )
                    if(bsDataState.headForwardState){
                        this.assessNew[3].list[0].status = this.$t('shape.item.abnormal')
                    }
                    rightHead++;
                }
                if(bsDataState.leftRoundShoulderState){
                    this.assessNew[1].list[leftHead].status = this.$t('shape.item.abnormal')
                }
                if(bsDataState.rightRoundShoulderState) {
                    this.assessNew[3].list[rightHead].status = this.$t('shape.item.abnormal')
                }
                // status 状态位置
                let indexRight = 1, indexLeft = 1
                if(rightHead){
                    indexRight = 2
                }
                if(leftHead){
                    indexLeft = 2
                }
                // 左边盆骨
                if(bsDataState.pfsVisualization){
                    this.assessNew[1].list.splice(indexLeft , 0, 
                            {
                                name: this.$t('newshape.item.pelvis'),
                                status: this.$t('shape.item.normal'),
                                errorLines: ['pelvisForwardUp', 'pelvisForwardDown', 'pelvisForward'],
                                errorPoints: ['left_hip_joint_point', 'right_hip_joint_point', 'left_body_gravity_line_point', 'right_body_gravity_line_point'], // 待定
                            },
                        )
                    if (bsDataState.pelvisForwardState) {
                        this.assessNew[1].list[indexLeft].status = this.$t('shape.exception[5].title')
                    } 

                // 右边盆骨
                } else {
                    this.assessNew[3].list.splice(indexRight , 0, 
                            {
                                name: this.$t('newshape.item.pelvis'),
                                status: this.$t('shape.item.normal'),
                                errorLines: ['pelvisForwardUp', 'pelvisForwardDown', 'pelvisForward'],
                                errorPoints: ['left_hip_joint_point', 'right_hip_joint_point', 'left_body_gravity_line_point', 'right_body_gravity_line_point'], // 待定
                            },
                        )
                    if(bsDataState.pelvisForwardState){
                        this.assessNew[3].list[indexRight].status = this.$t('shape.exception[5].title')
                    }
                }
                // 左膝评估
                if(bsDataState.leftKneeCheckState){
                    const length = this.assessNew[1].list.length-1
                    if(bsDataState.leftKneeCheckState === 1 || bsDataState.leftKneeCheckState === 3) {
                        this.assessNew[1].list[length].status = this.$t('shape.exception[7].title')
                    } else {
                        this.assessNew[1].list[length].status = this.$t('shape.exception[6].title')
                    }
                }
                // 右膝
                if(bsDataState.rightKneeCheckState){
                    const length = this.assessNew[3].list.length-1
                    if(bsDataState.rightKneeCheckState === 1 || bsDataState.rightKneeCheckState === 3) {
                        this.assessNew[3].list[length].status = this.$t('shape.exception[9].title')
                    } else {
                        this.assessNew[3].list[length].status = this.$t('shape.exception[8].title')
                    }
                }
                // 肩部
                if (bsDataState.highLowShoudlerState) {
                    if(bsDataState.highLowShoudlerState === 1 || bsDataState.highLowShoudlerState === 3){
                        this.assessNew[2].list[0].status = this.$t('shape.exception[2].title')
                    } else {
                        this.assessNew[2].list[0].status = this.$t('shape.exception[3].title')
                    }
                }
            }
        },
        goAi() {
            let shareScanId = window.localStorage.getItem('shareScanId')
            //  分享链接进入取分享报告ScanId
            let shareFlag = !this.share && shareScanId

            this.aiData = {
                url: this.aiUrl,
                abnormalConclusionInterpret: this.aiStatue.abnormalConclusionInterpret,
                abnormalOutlineInterpret: this.aiStatue.abnormalOutlineInterpret,
                scanId: shareFlag ? shareScanId : this.historyScanId ? this.historyScanId : this.massInfo.scanId
            }
            this.aiLoading = true
        },
        goAiUrl(url) {
            window.location.href = url
        }
    },
}
</script>

<style lang="less" scoped>
.menu-info {
    width: 26px;
    height: 26px;
}

.ai-entrance {
    position: fixed;
    height: 56px;
    width: auto;
    bottom: 48px;
    right: 10px;
    z-index: 99;
}

.report-wrapper {
    overflow: scroll;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, #262849, #07090f);
    display: flex;
    flex-direction: column;
    .van-popup--center {
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.9);
        .share-box {
            width: 302px;
            height: 170px;
            font-family: PingFangSC-Regular, PingFang SC;
            border-radius: 6px;
            p {
                margin: 24px 20px 21px;
                font-weight: 400;
                font-size: 14px;
                color: #111111;
                line-height: 20px;
                font-style: normal;
                letter-spacing: 0.8px;
            }
            .share-box-dev {
                background: #d8dcdf;
                border-radius: 4px;
                backdrop-filter: blur(10px);
                width: 238px;
                height: 48px;
                margin: auto;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #1677ff;
                line-height: 17px;
                text-align: left;
                padding: 8px;
                span {
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* 限制为三行 */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
            .share-box-btn {
                margin: 13px auto;
                font-weight: 400;
                font-size: 14px;
                font-style: normal;
                color: #222222;
                width: 238px;
                height: 36px;
                line-height: 36px;
                border-radius: 4px;
                background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
            }
        }
    }
    .de-DE {
        .share-box {
            height: 205px;
        }
    }
    .es-ES,
    .it-IT,
    .fr-FR,
    .ru-RU,
    .pt-PT,
    .id-ID,
    .tr-TR,
    .pt-PTO {
        .share-box {
            height: 190px;
        }
    }

    .header-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        width: 100%;
        top: 0;
        z-index: 100;
        position: relative;

        > span {
            img {
                display: block;
            }
        }
        .product-name {
            position: absolute;
            left: 55px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 15px;
            font-weight: 400;
            background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .product-name-qk {
            position: absolute;
            left: 55px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 15px;
            font-weight: 400;
            color: #009fe8 !important;
        }

        .right-menu,
        .right-menu-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            nav {
                display: flex;

                .right-menu-icon {
                    width: 26px;
                    height: 26px;
                }

                span {
                    margin-left: 3px;
                    font-size: 13px;
                    color: #ffffff;
                    background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .send-report {
                margin-left: 16px;
            }
        }
        .right-menu-right {
            div {
                margin-left: 12px;
            }
        }
    }

    .content-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 18px;
        width: 100%;

        .time {
            font-size: 12px;
            color: #8aa3be;
        }

        .right-menu {
            right: 0px;
            display: flex;

            .left-icon {
                width: 18px;
                height: 18px;
            }

            .right-icon {
                width: 25px;
                height: 18px;
                margin-left: 22px;
            }
        }
    }

    .report-result {
        margin: 15px 15px 0 11px;

        .result-title {
            width: 141px;
            height: 30px;
            margin-bottom: -2px;
            line-height: 29px;
            text-align: center;
            color: #222222;
            background-image: url(../../assets/images/report/body_over_zh.png);

            span {
                width: 98px;
                height: 14px;
                font-size: 14px;
            }
        }

        .result-wrapper {
            width: 353px;
            height: 229px;
            background-image: url(../../assets/images/report/bj1.png);

            .conent-box {
                flex-direction: column;
            }

            .cont-box {
                width: 353px;
                display: flex;
                align-items: end;
                justify-content: center;
            }

            .con-box {
                width: 353px;
                height: 36px;
                margin-top: 16px;
                display: flex;
                align-items: center;
                justify-content: center;

                // flex-direction: column;
                img {
                    width: 44px;
                    height: 4px;
                }

                p {
                    padding: 0 31px;
                    font-size: 36px;
                    background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    .unit {
                        font-size: 12px;
                        color: #8aa3be !important;
                    }
                }
            }

            .last-suggest {
                margin: 0 14px;
            }

            > div {
                display: flex;
                align-items: flex-start;
                padding-bottom: 10px;

                img {
                    display: block;
                    width: 19px;
                    // 禁止部分安卓机下图片自动放大
                    pointer-events: none;
                }

                > div {
                    color: #fff;
                    font-size: 14px;
                    padding-left: 9px;

                    h5 {
                        font-size: 14px;
                        font-weight: normal;
                        padding-bottom: 2px;
                        text-align: left;
                    }

                    p {
                        color: #8aa3be;
                        line-height: 1.29;
                        text-align: left;

                        &.bs-conclusion {
                            font-size: 0;

                            span {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    .report-components {
        width: 353px;
        height: 120px;
        margin: 14px 15px 15px 11px;
        background-image: url(../../assets/images/report/bj3.png);

        .components-box {
            color: #bcd0e5;
            background-image: url(../../assets/images/report/contrast_last.png);
            background-repeat: no-repeat;
            background-position: right;
            background-size: 108px 21px;
            text-align: right;
            margin-right: 1px;

            span {
                font-size: 11px;
                margin: 0 3px;
            }
        }
    }

    main {
        .loading {
            margin-top: 144px;
        }

        .report-img {
            width: 100%;
            min-height: 530px;
            display: none;

            &.show {
                display: block;
            }

            > div {
                width: 100%;

                > img {
                    width: 100%;
                    -webkit-touch-callout: default;
                }
            }
        }
    }

    .top-show-message {
        padding-top: 35px;
        margin-bottom: 30px;
        // background-image: url(../../assets/images/report_ground_bg.png);
        background-size: 100% 62px;
        background-repeat: no-repeat;
        background-position: left bottom;
        display: flex;

        .person-img {
            width: 52.27%;
            /*padding-left: 43px;*/
            margin-bottom: -2px;
            background-image: url(../../assets/images/report_moxing_bg.png);
            background-size: 125px 45px;
            background-repeat: no-repeat;
            background-position: 35px bottom;
            padding-bottom: 14px;
            height: 70vw;
        }

        .right-info {
            width: 47.73%;
            margin-top: 32px;

            // > div {
            //     width: 137px;
            //     height: 137px;
            //     // line-height: 137px;
            //     text-align: center;
            //     // background-image: url(../../assets/images/report_fraction_def.png);
            //     // background-repeat: no-repeat;
            //     // background-size: 137px 137px;
            //     font-size: 65px;
            //     color: #fff;
            //     margin-bottom: 16px;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     flex-direction: column;
            // }
            > p {
                font-size: 12px;
                color: #fff;
                line-height: 2;
                text-align: left;
                padding-left: 26px;
            }

            .single-score-box {
                position: relative;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                width: 88px;
                height: 82px;
                margin-left: 26px;
                margin-bottom: 64px;
                border: 1px solid transparent;
                border-radius: 6px;
                background: linear-gradient(#212440, #212440),
                    linear-gradient(90deg, rgba(0, 227, 201, 1) 0%, rgba(0, 159, 232, 1) 100%);
                background-clip: padding-box, border-box;
                background-origin: border-box;

                .con-box {
                    position: absolute;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 36px;
                    top: 0;
                    right: 0;
                    height: 17px;
                    background: linear-gradient(90deg, rgba(0, 227, 201, 1) 0%, rgba(0, 159, 232, 1) 100%);
                    border-radius: 0px 6px 0px 6px;

                    span {
                        margin-left: 4px;
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 1);
                    }
                }

                .score {
                    color: #fff;
                    font-size: 40px;
                    font-weight: 100;
                    line-height: 1;
                }

                .text {
                    color: #00a1e8;
                    font-size: 12px;
                    line-height: 1;
                    margin-bottom: 5px;
                }
            }

            .multiple-score-box {
                display: flex;
                flex-direction: row;
                width: 131px;
                height: 82px;
                margin-left: 26px;
                margin-bottom: 64px;
                border: 1px solid transparent;
                border-radius: 6px;
                background: linear-gradient(#212440, #212440),
                    linear-gradient(90deg, rgba(0, 227, 201, 1) 0%, rgba(0, 159, 232, 1) 100%);
                background-clip: padding-box, border-box;
                background-origin: border-box;

                div {
                    position: relative;
                    width: 50%;
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-direction: column;

                    .con-box {
                        position: absolute;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 36px;
                        top: 0;
                        height: 17px;
                        background: linear-gradient(90deg, rgba(0, 227, 201, 1) 0%, rgba(0, 159, 232, 1) 100%);
                        border-radius: 0px 0px 6px 6px;

                        span {
                            margin-left: 4px;
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 1);
                        }
                    }

                    .score {
                        color: #fff;
                        font-size: 40px;
                        font-weight: 100;
                        line-height: 1;
                    }

                    .text {
                        color: #00a1e8;
                        font-size: 12px;
                        line-height: 1;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    &.fix-background {
        height: 100%;
        overflow: hidden;
    }
}

.section {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.fixedBox {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 7.68px;
}

.spring-frame {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    z-index: 99;

    .spring-frame-box {
        width: 302px;
        min-height: 180px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 6px;
        backdrop-filter: blur(10px);
        margin: 222px auto;
        overflow: hidden;

        h6 {
            width: 238px;
            padding: 0 32px;
            margin: 32px;
            height: 20px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 20px;
        }

        button {
            margin-top: 30px;
            text-align: center;
            width: 238px;
            height: 36px;
            background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
            border-radius: 4px;
        }
    }
}
.prediction-data-box{
	position: relative;
	top: 30px;
}

@-webkit-keyframes slideShine {
    0% {
        padding-bottom: 14px;
        opacity: 1;
    }

    100% {
        padding-bottom: 24px;
        opacity: 0.2;
    }
}

@keyframes slideShine {
    0% {
        padding-bottom: 14px;
        opacity: 1;
    }

    100% {
        padding-bottom: 24px;
        opacity: 0.2;
    }
}
</style>
<style lang="less" scoped>
.qk-box {
    .van-tabs {
        /deep/.van-tabs__wrap {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                border: 0.5px solid #ffffff;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                opacity: 0.1;
            }
        }

        /deep/.van-tabs__nav {
            background-color: #232543;
        }

        /deep/.van-tab {
            color: #8aa3be;
        }

        /deep/.van-tab--active {
            display: inline-block;
            background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
            -webkit-background-clip: text !important;
            -webkit-text-fill-color: transparent !important;
            line-height: 42px;
            align-items: center;
        }
    }
}
.van-tabs {
    /deep/.van-tabs__wrap {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            border: 0.5px solid #ffffff;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            opacity: 0.1;
        }
    }

    /deep/.van-tabs__nav {
        background-color: #232543;
    }

    /deep/.van-tab {
        color: #8aa3be;
    }
    /deep/.van-tab--active {
        display: inline-block;
        color: #1bb2f8 !important;
        line-height: 42px;
        align-items: center;
    }
}

// .side-bar{
//     &.id-ID {
//     /deep/.side-list[data-v-46eefe02] {
//         padding-left: 32px;
//     }
// }
// }

.ar-AR {
    /deep/ .button-wrappers .wrapper-list .error-color[data-v-b41eac8e] {
        max-width: 120px !important;
    }
    /deep/.result-wrapper > div > div p {
        text-align: right;
        margin-right: 33px;
    }
    direction: rtl;
    .product-name,
    .product-name-qk {
        margin-left: 200px;
    }
    .right-icon {
        margin-left: 0 !important;
        margin-right: 5px;
    }
    .left-icon{
        margin-left: 12px;
    }
    .right-menu-icon{
        transform: scale(1);
    }
    .menu-info{
        transform: scale(1);
    }
    
}
</style>
<style>
.van-tabs__line {
    bottom: 14px;
    background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
}
</style>
