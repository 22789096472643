import { render, staticRenderFns } from "./IframeTemplatepre.vue?vue&type=template&id=275c41e8&scoped=true&"
import script from "./IframeTemplatepre.vue?vue&type=script&lang=js&"
export * from "./IframeTemplatepre.vue?vue&type=script&lang=js&"
import style0 from "./IframeTemplatepre.vue?vue&type=style&index=0&id=275c41e8&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275c41e8",
  null
  
)

export default component.exports